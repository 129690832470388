.contact-form {
  width: 60%;

  ul {
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-bottom: 10px;
      opacity: 0;
      overflow: hidden;
      display: block;
      clear: both;
      position: relative;
      animation: fadeInUp 2s 2s;
      animation-fill-mode: forwards;
    }

    li.half {
      width: 49%;
      margin-left: 2%;
      float: left;
      clear: none;

      &:first-child {
        margin-left: 0;
      }
    }

    p{
      color:#333333;
    }
  }

  input[type='text'],
  input[type='email'] {
    width: 100%;
    border: 1px solid #333333;
    background: white;
    height: 50px;
    font-size: 16px;
    color: #333333;
    padding: 0 20px;
    box-sizing: border-box;
    border-radius: 5px;
  }

  textarea {
    width: 100%;
    border: 1px solid #333333;
    background: white;
    height: 50px;
    font-size: 16px;
    color: #333333;
    padding: 20px;
    box-sizing: border-box;
    min-height: 150px;
    border-radius: 5px;
  }

  .flat-button {
    color: #333333;
    font-size: 11px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: 1px solid #333333;
    float: left;
    border-radius: 4px;
    background: 0 0;
    text-transform: uppercase;
    float: right;
    text-align: center;
    margin-right: 10px;
    cursor: pointer !important;
  }
}

.leaflet-container {
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: backInRight 1s 1.0s;
  animation-fill-mode: forwards;
}

@media screen and (max-width: 1200px) {
  .contact-form {
    width: 90%;
    margin-top: 15%;
    margin-left: 5%;
  }
 
}

.contact-page-title {
  font-size: 40px;
  font-family: 'Coolvetica';
  color: #333333;
  font-weight: 400;
  margin-top: 0;
  position: relative;
  margin-bottom: 40px;
  left: -10px;

  &:before {
    content: '<h1>';
    font-family: 'Quicksand';
    color: #333333;
    font-size: 18px;
    position: absolute;
    margin-top: -10px;
    left: 50px;
    opacity: 0.6;
    line-height: 18px;
  }

  &:after {
    content: '<h1/>';
    font-family: 'Quicksand';
    color: #333333;
    font-size: 18px;
    line-height: 18px;
    position: absolute;
    left: 30px;
    bottom: -20px;
    margin-left: 20px;
    opacity: 0.6;
  }
}