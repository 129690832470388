.about-page {
  .about-page-title {
    font-size: 40px;
    font-family: 'Coolvetica';
    color: #2e2d2d;
    font-weight: 400;
    margin-top: 0;
    position: relative;
    margin-bottom: 40px;
    left: -10px;
  
    &:before {
      content: '<h1>';
      font-family: 'Quicksand';
      color: #333333;
      font-size: 16px;
      position: absolute;
      margin-top: -10px;
      left: 160px;
      opacity: 0.6;    line-height: 18px;
    }
  
    &:after {
      content: '<h1/>';
      font-family: 'Quicksand';
      color: #333333;
      font-size: 16px;
      line-height: 18px;
      position: absolute;
      left: 140px;
      bottom: -20px;
      margin-left: 20px;
      opacity: 0.6;
    }
  }
  
  .text-zone {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 90%;
    width: auto;
    text-align: center; 
    font-family: 'Quicksand';

    p {
      font-size: 19px !important;
      color: #2e2d2d !important;
      font-family: 'Quicksand';
      font-weight: 300;
      max-width: fit-content;
      animation: pulse 1s;
      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }
      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }
      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
    }
  }
  .text-animate-hover {
    &:hover {
      color: red;
    }
  }
}


@media screen and (max-width: 1200px) {
  .about-page {
    .about-page-title {
      &:before {
        font-size: 14px !important;
        left: 50px !important;
      }

      &:after {
        font-size: 14px !important;
        left: 30px !important;
      }
    }

    .text-zone {
      p {
        font-size: 16px !important;
      }
    }
  }
}