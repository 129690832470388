
.privacy-page-title {
  font-size: 40px;
  font-family: 'Coolvetica';
  color: #333333;
  font-weight: 400;
  margin-top: 15;
  position: relative;
  text-align: center; 
  margin-bottom: 40px;
  left: -10px;
  white-space: nowrap;
  overflow-x: auto; 

}

p {
  font-size: 13px;
  color: #333333;
  font-family: sans-serif;
  font-weight: 300;
  max-width: fit-content;
  animation: pulse 1s;
  &:nth-of-type(1) {
    animation-delay: 1.1s;
  }
  &:nth-of-type(2) {
    animation-delay: 1.2s;
  }
  &:nth-of-type(3) {
    animation-delay: 1.3s;
  }
}

.privacy-text-zone {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90%;
  width: auto;
  text-align: left; 
  overflow: scroll;
}

.privacy-policy {
  text-align: left; 

}

.privacy-policy h2 {
  color: #333333;
  text-align: left; 

}

.privacy-policy ul {
  color: #333333;
  text-align: left; 
  font-size: 13px;

}

.privacy-policy ul li {
  color: #333333;
  text-align: left; 
  font-size: 13px;

}

.text-animate-hover {
  &:hover {
    color: #333333;
  }
}

@media screen and (max-width: 1200px) {
  .privacy-page-title {
    &:before {
      left: 50px !important;
    }

    &:after {
      left: 30px !important;
    }
  }

  .privacy-text-zone {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 90%;
    width: 80%;
    text-align: left; 
    overflow: scroll;
  }
}