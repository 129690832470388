.page {
  width: 100%;
  height: 100%;
  position: absolute;
}

.top-tags {
  bottom: auto;
  top: 70px !important;
}

.top-title-tags-html {
  top: 45px;
}

.top-tags-html {
  top: 20px;
  margin-left: -20px;
}

.tags {
  color: #333333;
  opacity: 0.6;
  position: absolute;
  bottom: 0;
  left: 120px;
  font-size: 20px;
  font-family: 'Typewriter';
}

.bottom-tag-html {
  margin-left: -20px;
}

.bottom-tag-html {
  margin-left: -20px;
}

.container {
  width: 100%;
  will-change: contents;
  height: 90%;
  min-height: 566px;
  position: absolute;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}

.text-zone p{
  font-size: 16px !important;
}

@media screen and (max-width: 1200px) {
  .page {
    position: initial;

    .text-zone {
      width: 90% !important;

      h1 {
        font-size: 40px !important;
      }

      p{
        font-size: 12px !important;
      }

      .about-page-title,
      .service-page-title,
      .contact-page-title {
        font-size: 30px !important;
      }
    }

    .top-tags {
      bottom: auto;
      top: 70px !important;
    }
    
    .top-title-tags-html {
      top: 45px;
    }
    
    .top-tags-html {
      top: 20px;
      margin-left: -20px;
    }
    
    .tags {
      color: #0a0a0a;
      opacity: 0.6;
      position: absolute;
      bottom: 10px;
      left: 30px;
      font-size: 14px;
      font-family: 'Typewriter';
    }
    
    .bottom-tag-html {
      margin-left: -20px;
    }
    
    .bottom-tag-html {
      margin-left: -20px;
    }

    .text-zone {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-height: 90%;
      text-align: center; /* Optional: centers the text within the container */
  
      .flat-home-button {
        color: #ffd700;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #ffd700;
        animation: fadeInAnimation 1s 1.8s backwards;
        white-space: nowrap;
        top: 50px;
    
        &:hover {
          background: #ffd700;
          color: #333;
        }
      }
    }
  }
  

}