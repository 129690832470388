
.service-page-title {
  font-size: 40px;
  font-family: 'Coolvetica';
  color: #2e2d2d;
  font-weight: 400;
  margin-top: 0;
  position: relative;
  margin-bottom: 40px;
  left: -10px;

  &:before {
    content: '<h1>';
    font-family: 'Quicksand';
    color: #333333;
    font-size: 16px;
    position: absolute;
    margin-top: -10px;
    left: 160px;
    opacity: 0.6;
    line-height: 18px;
  }

  &:after {
    content: '<h1/>';
    font-family: 'Quicksand';
    color: #333333;
    font-size: 16px;
    line-height: 18px;
    position: absolute;
    left: 140px;
    bottom: -20px;
    margin-left: 20px;
    opacity: 0.6;
  }
}

p {
  font-size: 13px;
  color: #fff;
  font-family: sans-serif;
  font-weight: 300;
  max-width: fit-content;
  animation: pulse 1s;
  &:nth-of-type(1) {
    animation-delay: 1.1s;
  }
  &:nth-of-type(2) {
    animation-delay: 1.2s;
  }
  &:nth-of-type(3) {
    animation-delay: 1.3s;
  }
}

.text-zone {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90%;
  width: auto;
  text-align: center; 
}

.text-animate-hover {
  &:hover {
    color: #fff;
  }
}

@media screen and (max-width: 1200px) {
  .service-page-title {
    &:before {
      left: 50px !important;
    }

    &:after {
      left: 30px !important;
    }
  }
}